import { graphql } from "gatsby"
import React from "react"

import ProductsCarousel from "./ProductsCarousel"

export const fragment = graphql`
  fragment ProductsCarouselFragment on WpPage_Flexlayouts_FlexibleLayouts_ProductsCarousel {
    sectionHeading
    button {
      url
      title
      target
    }
    products {
      product {
        ...SimpleProductQuery
        ...VariableProductQuery
      }
    }
    config {
      backgroundColour
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`

export const ACFProductsCarousel = ({
  sectionHeading,
  button,
  products,
  config,
}) => {

  return (
    <ProductsCarousel
      sectionHeading={sectionHeading}
      button={button}
      products={products}
      config={config}
    />
  )
}
