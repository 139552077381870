import React, { useRef, useState } from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { v4 as uuidv4 } from "uuid"
import { Container } from "react-bootstrap"

import { selectFirstVariant } from "../../../utils"
import { Section, SectionHeading, LinkButton } from "../../UI/Common"
import Card from "./Card"
import { infiniteScrollSettings } from "../../UI/Slider/Slide"
import { PrevButton, NextButton } from "../../UI/Slider/SliderArrows"

import * as style from "./ProductsCarousel.module.scss"

const ProductsCarousel = ({ sectionHeading, button, products, config }) => {
  const { padding } = config || {}

  const [slide, setSlide] = useState(0)
  const slides = []

  const filteredProducts = products?.filter(item => item.product !== null)

  filteredProducts.forEach(item => {
    const product = item.product || item

    if (product.nodeType.match(/simple/i)) {
      slides.push(product)
      return
    }

    if (!product.attributes) {
      return
    }
    if (product.nodeType.match(/variable/i)) {
      const selectedVariant = selectFirstVariant(product)
      selectedVariant && slides.push(selectedVariant)
      return
    }

    return
  })

  const slider = useRef(null)

  return (
    <Section className={style.sectionCls} padding={padding}>
      <div>
        <Container fluid="lg">
          <div className="d-flex flex-column flex-md-row justify-content-md-between p-2">
            <SectionHeading text={sectionHeading} />
            {button && (
              <LinkButton
                link={button?.url}
                title={button?.title}
                target={button?.target}
                variant="outlined"
              />
            )}
          </div>
        </Container>

        <div className={style.wrapper}>
          <div className={style.innerWrapper}>
            <div>
              <PrevButton ref={slider} show={[slide, setSlide]} />
            </div>
            <div>
              <NextButton ref={slider} show={[slide, setSlide]} />
            </div>
            <Slider ref={slider} {...infiniteScrollSettings}>
              {slides.map(item => {
                return <Card key={uuidv4()} product={item} />
              })}
            </Slider>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ProductsCarousel
