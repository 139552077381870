import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { createDisplayPrice } from "../../../utils/productsHelper"
import SaleFlag from "../../../templates/ProductUI/SaleFlag"
import {
  boxCls,
  linkCls,
  productImgCls,
  textAreaCls,
  nameWrapperCls,
  productNameCls,
  salePriceCls,
  textThroughCls,
  textCls,
  saleFlag,
} from "./QuizResultCard.module.scss"

const QuizResultCard = ({ className, product, featured }) => {
  const {
    name,
    featuredImage,
    imageSrc,
    imageAlt,
    onSale,
    price,
    regularPrice,
  } = product

  return (
    <>
      <div className={boxCls} key={product.id}>
      <a className={linkCls} href={`/products/${product.id}`} target='_blank' rel="noopener">
        {onSale && (
          <div className={saleFlag}>
            <SaleFlag />
          </div>
        )}
        {featuredImage ? (
          <GatsbyImage
            className={productImgCls}
            image={getImage(featuredImage.node.localFile)}
            alt={featuredImage.node.altText}
          />
        ) : (
          <img className={productImgCls} src={imageSrc} alt={imageAlt} />
        )}
        <div className={textAreaCls}>
          <div className={nameWrapperCls}>
            {/* TODO: swap with getDynamicProductLink */}

              <p className={productNameCls}>{name}</p>

          </div>
          {onSale ? (
            <>
              <p className={`${salePriceCls} mb-0`}>
                <span className={textThroughCls}>
                  {createDisplayPrice(regularPrice, "QuizResultCard onSale")}
                </span>{" "}
                {createDisplayPrice(price, "QuizResultCard onSale2")}
              </p>
            </>
          ) : (
            <p className={textCls}>{createDisplayPrice(price, "QuizResultCard notOnSale")}</p>
          )}
        </div>
        </a>
      </div>
    </>
  )
}

export default QuizResultCard
