import * as PropTypes from "prop-types"
import React from "react"
import { Form, Col } from "react-bootstrap"

import * as styles from "./QuizAnswerSelector.module.scss"

const QuizAnswerSelector = ({
  answerKey,
  question,
  isSelected,
  handleSelectAnswer,
  className,
}) => {
  const { answerImgs, answerDescription, answers, filters } = question

  const handleChange = () => {
    handleSelectAnswer(answers[answerKey], filters[answerKey])
  }

  const textAreaStyles = answerDescription[answerKey] ? `${styles.textArea} ${styles.large}`: styles.textArea

  return (
    <Col className={className}>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={handleChange}
        onClick={handleChange}
        className={`${styles.answerBox} ${isSelected &&
          styles.answerBoxSelected}`}>
        <img
          className={styles.imgCls}
          src={answerImgs[answerKey]}
          alt={answers[answerKey]}
        />
        <div className={textAreaStyles}>
          <div>
          <p className={styles.labelCls}>{answers[answerKey]}</p>
          {answerDescription[answerKey] ? (
            <p className={styles.descriptionCls}>
              {answerDescription[answerKey]}
            </p>
          ) : (
            ""
          )}
          </div>
        </div>
      </div>
      <div className="invisible">
        <Form.Check
          readOnly
          type="checkbox"
          id={`default-${answerKey}`}
          checked={isSelected}
        />
      </div>
    </Col>
  )
}

export default QuizAnswerSelector
