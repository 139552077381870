// extracted by mini-css-extract-plugin
export var bgGrey100 = "QuizResultSection-module--bg-grey-100--7a316";
export var bgGrey150 = "QuizResultSection-module--bg-grey-150--52d6d";
export var bgGrey200 = "QuizResultSection-module--bg-grey-200--9df86";
export var bgGrey300 = "QuizResultSection-module--bg-grey-300--285fc";
export var bgGrey400 = "QuizResultSection-module--bg-grey-400--99f32";
export var bgGrey500 = "QuizResultSection-module--bg-grey-500--7ea3d";
export var bgGrey600 = "QuizResultSection-module--bg-grey-600--c6b65";
export var bgGrey700 = "QuizResultSection-module--bg-grey-700--e40ef";
export var bgGrey800 = "QuizResultSection-module--bg-grey-800--2579b";
export var bgGrey900 = "QuizResultSection-module--bg-grey-900--5512e";
export var container = "QuizResultSection-module--container--1b560";
export var description = "QuizResultSection-module--description--c0624";
export var feacturedContainer = "QuizResultSection-module--feacturedContainer--7b28d";
export var loadingContainer = "QuizResultSection-module--loadingContainer--f1c78";
export var quizBox = "QuizResultSection-module--quizBox--7a100";
export var quizBoxAction = "QuizResultSection-module--quizBoxAction--a53a6";
export var quizBoxContent = "QuizResultSection-module--quizBoxContent--9f891";
export var quizBoxCornerImg = "QuizResultSection-module--quizBoxCornerImg--ea93c";
export var quizBoxRightCornerImg = "QuizResultSection-module--quizBoxRightCornerImg--6e20e";
export var retakeLink = "QuizResultSection-module--retakeLink--5af14";
export var textGrey100 = "QuizResultSection-module--text-grey-100--c3d88";
export var textGrey150 = "QuizResultSection-module--text-grey-150--1a81f";
export var textGrey200 = "QuizResultSection-module--text-grey-200--354b7";
export var textGrey300 = "QuizResultSection-module--text-grey-300--c9954";
export var textGrey400 = "QuizResultSection-module--text-grey-400--e03da";
export var textGrey500 = "QuizResultSection-module--text-grey-500--1eab3";
export var textGrey600 = "QuizResultSection-module--text-grey-600--410f1";
export var textGrey700 = "QuizResultSection-module--text-grey-700--860de";
export var textGrey800 = "QuizResultSection-module--text-grey-800--2b601";
export var textGrey900 = "QuizResultSection-module--text-grey-900--e0d54";