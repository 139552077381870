import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  AnswersShape,
  QuestionsShape,
  QuizHeaderShape,
  ResultsHeaderShape,
  ResultsShape,
} from "./quizShapes"

import QuizQuestionSection from "./QuizQuestionSection"
import QuizResultSection from "./QuizResultSection"


const RecommendationQuiz = ({
  pageContext,
  pageTitle,
  quizHeader,
  resultsHeader,
  questions,
  defaultProducts
}) => {
  const initialState = {
    showResults: false,
    filters: [],
  }
  const [state, setState] = useState(initialState)

  const handleFinishQuiz = results => {
    const { filters } = results
    setState({ showResults: true, filters })
  }

  const handleReTakeQuiz = () => {
    setState(initialState)
  }
  const content = !state.showResults ? (
    <QuizQuestionSection
      pageContext={pageContext}
      quizHeader={quizHeader}
      questions={questions}
      onFinish={handleFinishQuiz}
    />
  ) : (
    <QuizResultSection
      pageContext={pageContext}
      resultsHeader={resultsHeader}
      filters={state.filters}
      onReTakeQuizClick={handleReTakeQuiz}
      defaultProducts={defaultProducts}
    />
  )
  return <div><h1 className="p-3 text-center">{pageTitle}</h1>{content}</div>
}

RecommendationQuiz.propTypes = {
  answers: AnswersShape,
  pageContext: PropTypes.object,
  questions: QuestionsShape,
  quizHeader: QuizHeaderShape,
  results: ResultsShape,
  resultsHeader: ResultsHeaderShape,
}

RecommendationQuiz.defaultProps = {
  answers: {},
  pageContext: {},
  questions: [],
  quizHeader: {},
  resultsHeader: {},
  results: {},
}

export default RecommendationQuiz
