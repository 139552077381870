import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { SectionHeading } from "../../UI/Common"
import { fetchProducts } from "../../../api/products"
import { DEFAULT_END_SLIDER_PADDING } from "../../../utils/acfComponentHelper"

import ProductsCarousel from "../../AcfPageBlocks/ProductsCarousel"
import {
  getNumericPrice,
} from "../../../utils/productsHelper"

import { PAGE, SIZE } from "./recommendationQuizUtils"
import QuizResultCard from "./QuizResultCard"
import * as styles from "./QuizResultSection.module.scss"

const orderbyPrice = (products,selected) => {
  return products.sort((a, b) => {
    const priceA =
      typeof a.price === "string" ? getNumericPrice(a.price) : a.price
    const priceB =
      typeof b.price === "string" ? getNumericPrice(b.price) : b.price

    return (
      Math.abs(priceA - selected.priceMax) -
      Math.abs(priceB - selected.priceMax)
    )
  })
}

const QuizResultSection = ({  
  resultsHeader,
  filters,
  defaultProducts,
  onReTakeQuizClick,
  result,
}) => {
  const [data, setData] = useState({ fetching: true, displayProducts: [] })

  const [selected, setSelected] = useState({})
  
  let defaultOptions
  let featuredResults
  let secondaryResults
  let otherResults
  useEffect(() => {
    let apiFilterOptions = {}

    const selectedFilters = filters.reduce(
      (allFilters, currentFilter, index) => {
        let filter = {}
        let filters
        Object.entries(currentFilter).forEach(([key, value]) => {
          if (value !== {} && typeof value !== "function") {
            apiFilterOptions[key] = value
          }

          if (key === process.env.FILTER_LICENSE_ATTRIBUTE_ID.toString()) {
            const licenseLevels = value.split(",")
            filter = { license: licenseLevels[0] }
          } else {
            filter = { [key]: value }
          }
        })

        if(!allFilters.category) {
          filters = { ...allFilters, ...filter }
        } else {
          filters = filter.category !== "" ? { ...allFilters, ...filter } : allFilters
        }

        return filters
      },
      {}
    )
    setSelected(selectedFilters)

    fetchProducts(PAGE, SIZE, apiFilterOptions)
      .then(response => {
        setData({
          ...data,
          fetching: false,
          displayProducts: [...response.data.data],
        })
      })
      .catch(error => {
        console.error(error)
      })
  }, [])

  if (!data.fetching) {
    // TODO: enable filtering by seatHeight. Requires product attribute data to be returned from api or seatHeight filtering capability in api
    //   const productsFilterdBySeatHeight = data.displayProducts.map(product => {
    //     const seatHeight = getProductAttributeValue(
    //       product,
    //       "Seat height"
    //     )
    //     const numericHeight = seatHeight
    //       ? Number(seatHeight.match(/\d+/))
    //       : null

    //     if (numericHeight) {
    //       return selected.filterBySeatHeight(numericHeight)
    //         ? product
    //         : null
    //     }

    //     return product
    //   })
    //   .filter(item => item !== null)

    //   setData({...data, displayProducts: productsFilterdBySeatHeight})

    const productsClosestPriceMatched = orderbyPrice(data.displayProducts, selected)

    defaultOptions = defaultProducts.reduce((allProducts, currentLicence) => {
      if (currentLicence.name === selected.license) {
        const products = [
          ...currentLicence.lowestPriceProducts.nodes,
          ...currentLicence.heighestPriceProducts.nodes,
        ]

        let uniqueProductIds = []
        let uniquePriceMatchedProductIds = productsClosestPriceMatched.map(
          product => product.id
        )

        const uniqueProducts = products.filter(product => {
          /* Remove duplicate products from:
                - overlapping high and low price queries 
                - any products returned from fetch (where defaults are givan as "other options.") */

          if (
            !uniquePriceMatchedProductIds.includes(product.id) &&
            !uniqueProductIds.includes(product.id)
          ) {
            uniqueProductIds.push(product.id)
            return product
          }

          return
        })

        return orderbyPrice(uniqueProducts, selected)
      }
      return allProducts
    }, [])

    featuredResults = productsClosestPriceMatched.length
      ? productsClosestPriceMatched.slice(0, 2)
      : defaultOptions.slice(0, 2)
    secondaryResults = productsClosestPriceMatched && productsClosestPriceMatched.length > 2 
      ? productsClosestPriceMatched.slice(2)
      : defaultOptions.slice(2)
    otherResults = productsClosestPriceMatched.length
      ? defaultOptions.slice(2)
      : []
  }

  return (
    <>
      <p className="d-flex justify-content-center mb-5">
        <a
          className={styles.retakeLink}
          role="button"
          tabIndex={0}
          onKeyDown={onReTakeQuizClick}
          onClick={onReTakeQuizClick}>
          Re-take the quiz
        </a>
      </p>
      {data.fetching ? <>
        <Container className={styles.loadingContainer}>
        <p className="display-4">
          Finding recommendations for you…
        </p>
      </Container>
      
      </> :  <>
      <Container>
        <SectionHeading text={resultsHeader.title} />
        <p className={`pt-4 ${styles.description}`}>
          {resultsHeader.description}
        </p>
      </Container>

      <Container className={styles.feacturedContainer}>
        <Row className="g-4">
          {featuredResults?.length > 0
            ? featuredResults.map(product => (
                <Col
                  sm={12}
                  lg={12 / featuredResults.length}
                  key={product.id}
                  className="pt-0 d-flex justify-content-center position-relative">
                  <div className="border border-secondary">
                    <QuizResultCard product={product} featured={true} />
                  </div>
                </Col>
              ))
            : null}
        </Row>
      </Container>

      {secondaryResults?.length > 0 && (
        <div className="bg-secondary pb-5">
          <Container className={`${styles.container} `}>
            <h2 className="mb-4">You might also be interested in… </h2>
            <Row className="g-4">
              {secondaryResults?.length
                ? secondaryResults.map(product => (
                    <Col
                      sm={12}
                      lg={6}
                      key={product.id}
                      className="d-flex justify-content-center position-relative mt-4">
                      <QuizResultCard product={product} featured={true} />
                    </Col>
                  ))
                : null}
            </Row>
          </Container>

          {otherResults?.length > 3 && (
            <ProductsCarousel
              sectionHeading="Other options available for your licence"
              products={otherResults}
              config={DEFAULT_END_SLIDER_PADDING}
            />
          )}
        </div>
      )} 
      </>}
      
    </>
  )
}

export default QuizResultSection
