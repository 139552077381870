// extracted by mini-css-extract-plugin
export var bgGrey100 = "QuizAnswerSilder-module--bg-grey-100--653c2";
export var bgGrey150 = "QuizAnswerSilder-module--bg-grey-150--9cc8e";
export var bgGrey200 = "QuizAnswerSilder-module--bg-grey-200--7708e";
export var bgGrey300 = "QuizAnswerSilder-module--bg-grey-300--cb3c8";
export var bgGrey400 = "QuizAnswerSilder-module--bg-grey-400--d583e";
export var bgGrey500 = "QuizAnswerSilder-module--bg-grey-500--f6e73";
export var bgGrey600 = "QuizAnswerSilder-module--bg-grey-600--a2af7";
export var bgGrey700 = "QuizAnswerSilder-module--bg-grey-700--e53d2";
export var bgGrey800 = "QuizAnswerSilder-module--bg-grey-800--1c623";
export var bgGrey900 = "QuizAnswerSilder-module--bg-grey-900--ae444";
export var nextBtnContent = "QuizAnswerSilder-module--nextBtnContent--3acee";
export var quizNextButton = "QuizAnswerSilder-module--quizNextButton--63e59";
export var sliderWrapper = "QuizAnswerSilder-module--sliderWrapper--3643c";
export var textGrey100 = "QuizAnswerSilder-module--text-grey-100--ef64e";
export var textGrey150 = "QuizAnswerSilder-module--text-grey-150--b55a5";
export var textGrey200 = "QuizAnswerSilder-module--text-grey-200--6d308";
export var textGrey300 = "QuizAnswerSilder-module--text-grey-300--b4738";
export var textGrey400 = "QuizAnswerSilder-module--text-grey-400--63c47";
export var textGrey500 = "QuizAnswerSilder-module--text-grey-500--68569";
export var textGrey600 = "QuizAnswerSilder-module--text-grey-600--97c95";
export var textGrey700 = "QuizAnswerSilder-module--text-grey-700--dff48";
export var textGrey800 = "QuizAnswerSilder-module--text-grey-800--5cd4c";
export var textGrey900 = "QuizAnswerSilder-module--text-grey-900--cbf57";