import React from "react"
import { Button as ReactBSButton } from "react-bootstrap"

import {
  linkClass,
  buttonClass,
  disabledClass,
  iconWrapperClass,
  outlineButtonClass,
  pillClass,
  primaryBg,
  spacedClass,
  squareClass
} from "./Button.module.scss"
// import { ChevronRight } from "./svg"

// const DefaultIcon = () => (
//   <div className={iconWrapperClass}>
//     <ChevronRight />
//   </div>
// )
const Icon = icon => {
  // const ButtonIcon = icon === "default" ? DefaultIcon : icon
  const ButtonIcon = icon
  return <ButtonIcon />
}


const Button = ({
  className,
  children,
  linkSytles,
  variant,
  shape,
  title,
  onClick,
  isDisabled,
  icon,
  spaced
}) => {
  let classList

  switch (variant) {
    case "primary":
      classList = `${buttonClass} ${primaryBg} ${className}`
      break
    case "primary-outlined":
      classList = `${buttonClass} ${primaryBg} ${outlineButtonClass} ${className}`
      break
    case "secondary-outlined":
      classList = `${buttonClass} ${outlineButtonClass} ${className}`
      break
    default:
      classList = `${buttonClass} ${className}`
  }

  if (shape === "square") {
    classList = `${classList} ${squareClass}`
  }
  if (shape === "pill") {
    classList = `${classList} ${pillClass}`
  }
  if (spaced) {
    classList = `${classList} ${spacedClass}`
  }
  if (isDisabled) {
    classList = `${classList} ${disabledClass}`
  }

  return (
    <ReactBSButton
      className={classList}
      variant={variant}
      onClick={onClick}
      disabled={isDisabled}
      spaced={spaced}>
      {title || children}
    </ReactBSButton>
  )
}

export default Button

// Button.propTypes = {
//   className: PropTypes.string,
//   linkSytles: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   onClick: PropTypes.oneOfType(PropTypes.func, PropTypes.string),
//   isDisabled: PropTypes.bool,
//   variant: PropTypes.oneOf([
//     "default",
//     "primary",
//     "primary-outlined",
//     "secondary",
//     "secondary-outlined",
//     null
//   ]),
//   shape: PropTypes.oneOf(["square", "rounded", "pill"]),
//   /**
//    * The icon prop accepts either an icon element or "default")
//    */
//   icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
//   spaced: PropTypes.bool
// }

// Button.defaultProps = {
//   className: null,
//   linkSytles: null,
//   onClick: null,
//   isDisabled: false,
//   variant: "default",
//   icon: DefaultIcon,
//   shape: "pill",
//   spaced: false
// }
