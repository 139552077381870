// extracted by mini-css-extract-plugin
export var bgGrey100 = "QuizResultCard-module--bg-grey-100--cde6b";
export var bgGrey150 = "QuizResultCard-module--bg-grey-150--33b26";
export var bgGrey200 = "QuizResultCard-module--bg-grey-200--a06ec";
export var bgGrey300 = "QuizResultCard-module--bg-grey-300--2a609";
export var bgGrey400 = "QuizResultCard-module--bg-grey-400--c478e";
export var bgGrey500 = "QuizResultCard-module--bg-grey-500--c17c9";
export var bgGrey600 = "QuizResultCard-module--bg-grey-600--ab545";
export var bgGrey700 = "QuizResultCard-module--bg-grey-700--a3a4f";
export var bgGrey800 = "QuizResultCard-module--bg-grey-800--3a81e";
export var bgGrey900 = "QuizResultCard-module--bg-grey-900--930d8";
export var boxCls = "QuizResultCard-module--boxCls--ab648";
export var headerCls = "QuizResultCard-module--headerCls--8384e";
export var linkCls = "QuizResultCard-module--linkCls--e2204";
export var nameWrapperCls = "QuizResultCard-module--nameWrapperCls--7763e";
export var productImgCls = "QuizResultCard-module--productImgCls--ad4ed";
export var productNameCls = "QuizResultCard-module--productNameCls--f5f99";
export var saleFlag = "QuizResultCard-module--saleFlag--488f2";
export var salePriceCls = "QuizResultCard-module--salePriceCls--9afec";
export var scrollerCls = "QuizResultCard-module--scrollerCls--f01f6";
export var textAreaCls = "QuizResultCard-module--textAreaCls--2297e";
export var textCls = "QuizResultCard-module--textCls--2c200";
export var textGrey100 = "QuizResultCard-module--text-grey-100--17d17";
export var textGrey150 = "QuizResultCard-module--text-grey-150--d7c0f";
export var textGrey200 = "QuizResultCard-module--text-grey-200--e5db3";
export var textGrey300 = "QuizResultCard-module--text-grey-300--d7377";
export var textGrey400 = "QuizResultCard-module--text-grey-400--40915";
export var textGrey500 = "QuizResultCard-module--text-grey-500--443d4";
export var textGrey600 = "QuizResultCard-module--text-grey-600--55c43";
export var textGrey700 = "QuizResultCard-module--text-grey-700--1a900";
export var textGrey800 = "QuizResultCard-module--text-grey-800--1b8a8";
export var textGrey900 = "QuizResultCard-module--text-grey-900--9bb45";
export var textThroughCls = "QuizResultCard-module--textThroughCls--65fb8";