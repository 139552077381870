import * as PropTypes from "prop-types"

export const AnswerShape = PropTypes.shape({
  title: PropTypes.string,
  responsibilities: PropTypes.arrayOf(
    PropTypes.shape({
      responsibility: PropTypes.string
    })
  ),
  link: PropTypes.shape({
    title: PropTypes.string,
    uri: PropTypes.string
  })
})

export const AnswersShape = PropTypes.shape({
  answerA: AnswerShape,
  answerB: AnswerShape,
  answerC: AnswerShape,
  answerD: AnswerShape
})

export const QuestionShape = PropTypes.shape({
  question: PropTypes.string,
  instructions: PropTypes.string,
  answers: PropTypes.shape({
    answerA: PropTypes.string,
    answerB: PropTypes.string,
    answerC: PropTypes.string,
    answerD: PropTypes.string
  })
})

export const QuestionsShape = PropTypes.arrayOf(QuestionShape)

export const QuizHeaderShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
})

export const ResultsHeaderShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
})

export const ResultAnswerShape = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string
})

export const ResultsShape = PropTypes.shape({
  answerA: ResultAnswerShape,
  answerB: ResultAnswerShape,
  answerC: ResultAnswerShape,
  answerD: ResultAnswerShape,
  answerMix: ResultAnswerShape
})
