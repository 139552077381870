// extracted by mini-css-extract-plugin
export var bgGrey100 = "Button-module--bg-grey-100--5acd3";
export var bgGrey150 = "Button-module--bg-grey-150--99372";
export var bgGrey200 = "Button-module--bg-grey-200--9ca95";
export var bgGrey300 = "Button-module--bg-grey-300--227ed";
export var bgGrey400 = "Button-module--bg-grey-400--b79cb";
export var bgGrey500 = "Button-module--bg-grey-500--ee151";
export var bgGrey600 = "Button-module--bg-grey-600--da743";
export var bgGrey700 = "Button-module--bg-grey-700--a1469";
export var bgGrey800 = "Button-module--bg-grey-800--7bbe5";
export var bgGrey900 = "Button-module--bg-grey-900--eba4b";
export var buttonClass = "Button-module--buttonClass--704fd";
export var disabledClass = "Button-module--disabledClass--23d32";
export var iconWrapperClass = "Button-module--iconWrapperClass--9de67";
export var linkClass = "Button-module--linkClass--86375";
export var outlineButtonClass = "Button-module--outlineButtonClass--51609";
export var pillClass = "Button-module--pillClass--7e5cd";
export var primaryBg = "Button-module--primaryBg--b2582";
export var spacedClass = "Button-module--spacedClass--95ffb";
export var squareClass = "Button-module--squareClass--48d84";
export var textGrey100 = "Button-module--text-grey-100--5fef2";
export var textGrey150 = "Button-module--text-grey-150--f0f3a";
export var textGrey200 = "Button-module--text-grey-200--b9209";
export var textGrey300 = "Button-module--text-grey-300--e43ff";
export var textGrey400 = "Button-module--text-grey-400--c4e43";
export var textGrey500 = "Button-module--text-grey-500--ed9d2";
export var textGrey600 = "Button-module--text-grey-600--ab173";
export var textGrey700 = "Button-module--text-grey-700--43499";
export var textGrey800 = "Button-module--text-grey-800--20fcc";
export var textGrey900 = "Button-module--text-grey-900--60499";