import parseHTML from "html-react-parser"
import { createDisplayPrice } from "../../../utils/productsHelper"

const calculateMonthlyFee = (loanamount, numpayments, baseannualrate )=> {
  /*
  /**
     * Calculate the monthly fee
     * @param  {Number} loanamount [the amount borrowed]
     * @param  {Number} numpayments [number of payments in months]
     * @return {Number} [the base percentage rate of the loan - A 6.9% Annual Rate should be passed in as 0.060 NOT 6.9]
  */

  let rate =  baseannualrate / 12;
  let totalmonthlypayment = ((loanamount) * rate * Math.pow(1+rate,numpayments)) / (Math.pow(1+rate, numpayments)-1);
return totalmonthlypayment

}
export const QUIZ_QUESTIONS = [
  {
    questionId: "01",
    questionType: "select",
    question: "What will you use your bike for mostly?",
    instructions: "",
    answerImgs: {
      answerA:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/08/large-commuting.png",
      answerB:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/08/large-around-town.png",
      answerC:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/08/large-touring.png",
      answerD:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/08/large-delivery.png",
    },
    answerDescription: {},
    answers: {
      answerA: "Commuting",
      answerB: "Getting around town",
      answerC: "Touring",
      answerD: "Delivery work",
    },
    filters: {
      answerA: {},
      answerB: {},
      answerC: {},
      answerD: {},
    },
  },
  {
    questionId: "02",
    questionType: "select",
    question: "What type of license do you have?",
    instructions:
      "Below you can see a list of licences you can get in the UK. Each of them entitles you to ride different categories of bikes so it is crucial to indicate the one you have.",
    link: {title: "Find out about licences and learning to ride", url: "/learn-to-ride"},
    answerImgs: {
      answerA:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/03/Piaggio-Liberty-50-E5-Bianco-Luna-Front-right.jpg",
      answerB:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/04/Vespa-GTS-Super-125-Tech-E5-Grigio-delicato-matt-front-left.jpg",
      answerC:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/03/Piaggio-MP3-Sport-Advanced-500-E5-Marrone-Terra-Front-Right.jpg",
      answerD:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/03/Moto-Guzzi-V7-Special-850-E5-Blu-Formale-Front-Right.jpg",
    },
    answerDescription: {
      answerA: "Mopeds upto 50cc · max 28mph · 16yrs +",
      answerB: "Motorcycles upto 125cc · max 11kw / 15BHp · 17yrs +",
      answerC: "Motorcycles upto 35kw / 47BHp · 19yrs +",
      answerD: "Any motorcycles · 21yrs +",
    },
    answers: {
      answerA: "AM",
      answerB: "A1",
      answerC: "A2",
      answerD: "A",
    },
    filters: {
      answerA: { [process.env.FILTER_LICENSE_ATTRIBUTE_ID]: "AM" },
      answerB: { [process.env.FILTER_LICENSE_ATTRIBUTE_ID]: "A1" },
      answerC: { [process.env.FILTER_LICENSE_ATTRIBUTE_ID]: "A2" },
      answerD: { [process.env.FILTER_LICENSE_ATTRIBUTE_ID]: "A" },
    },
  },
  {
    questionId: "03",
    questionType: "slider",
    question: "What is your budget?",
    instructions:
      "We have a wide range of new and used scooters and motorbikes so you can find a model in your price range. The quoted installments are based on 60 monthly payments.",
    link: {title: "Find out about our flexible financing plans", url: "/finance"},
    answers: {
      min: 1000,
      max: 15000,
    },
    labels: {
      min: parseHTML(
        "<span style='font-size:1rem; font-weight:500;'><span style='color:#2c2c2c'>£1,000</span><br/>£19.75 p/m</span>"
      ),
      max: parseHTML(
        "<span style='display: block; width: 120px; font-size:1rem; font-weight:500;'><span style='color:#2c2c2c'>£15,000</span><br/>£296.31 p/m</span>"
      ),
      getValueLabel: loanamount => {
        const interestRate = Number(process.env.IVENDI_INTEREST_RATE)
        const overXmonths = 60
        const installments = calculateMonthlyFee(loanamount, overXmonths, interestRate)
        return parseHTML(
          `<span><span style='font-size:1.5rem; color:#2c2c2c;'>${createDisplayPrice(
            loanamount, "recommendationQuizUtils span"
          )}</span><br/><span style='display:block; font-size:1.75rem; margin-top: 12px; color:#666;'>${createDisplayPrice(
            installments, "recommendationQuizUtils installments"
          )} p/m</span>`
        )
      },
    },
    createFilter: selection => {
      return {
        priceMax: selection,
      }
    },
  },
  {
    questionId: "04",
    questionType: "select",
    question: "Which type of bike are you most interested in?",
    instructions:
      "",
    answerImgs: {
      answerA:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/06/Royal-Enfield-Himalayan-Adventure-E5-Mirage-Silver-Right-1.png",
      answerB:
        "https://wp.centralbikes.co.uk/wp-content/uploads/2022/05/Vespa-Sei-Giorni-300-II-Edition-E5-Right-Side.jpg"
    },
    answerDescription: {},
    answers: {
      answerA: "Motorcycle",
      answerB: "Scooter",
    },
    filters: {
      answerA: { category: "4969,4972" },
      answerB: { category: "6179,6184" }
    },
  },
  {
    questionId: "05",
    questionType: "select",
    question: "Which bike style do you like most?",
    instructions:
      "If you know what style of bike you would like to ride, choose an option below.",
    // mvp trial implmentation of previous answer dependency.
    dependencyQuestionId: "04",
    dependencyAnswers: {
      Motorcycle: {
        answerImgs: {
          answerA:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/06/Royal-Enfield-Scram-411-E5-White-Flame-Right.jpg",
          answerB:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/05/Royal-Enfield-Classic-350-E5-Halcon-Grey-Right.jpg",
          answerC:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/06/Royal-Enfield-Meteor-350-E5-Fireball-Red-Right.jpg",
          answerD:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2020/07/Mens-Motorcycle-Rider-Gear-by-Central-Bikes-scaled-1.jpg",
        },
        answerDescription: {},
        answers: {
          answerA: "Adventure",
          answerB: "Classic / Retro",
          answerC: "Cruiser",
          answerD: "Sports"
        },
        filters: {
          answerA: { category: "6193,6202" },
          answerB: { category: "6187,6196" },
          answerC: { category: "4981,4994" },
          answerD: { category: "6190,6199" }
        },
      },
      Scooter: {
        answerImgs: {
          answerA:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/05/Vespa-GTS-300-Touring-E5-Grigio-Right-Side.jpg",
          answerB:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/04/Vespa-Eletrrica-45-Azzurro-Elettrico-Right-Side.jpg",
          answerC:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/04/Vespa-GTS-Super-125-Racing-Sixties-Bosco-Verde-Right-Side.jpg",
          answerD:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/03/Piaggio-MP3-Sport-Advanced-500-E5-Marrone-Terra-Right-Side.jpg",
          answerE:
            "https://wp.centralbikes.co.uk/wp-content/uploads/2022/03/Piaggio-One-Active-Arctic-Mix-Right-Side.jpg",
        },
        answerDescription: {},
        answers: {
          answerA: "Classic / Retro",
          answerB: "Electric",
          answerC: "Sports",
          answerD: "Tricycle",
          answerE: "Utility"
        },
        filters: {
          answerA: { category: "6208,6202" },
          answerB: { category: "4978,6196" },
          answerC: { category: "6253,4994" },
          answerD: { category: "6205,6199" },
          answerE: { category: "6211,6199" }
        },
      }
    }
  },
  {
    questionId: "06",
    questionType: "slider",
    question: "How tall are you?",
    instructions:
      "Your comfort of riding is important to us. Let us know your height so we can find the best models for you.",
    answers: {
      min: 145,
      max: 210,
    },
    labels: {
      min: parseHTML(
        "<span style='font-size:1rem; font-weight:500;'><span style='color:#2c2c2c'>145cm</span>"
      ),
      max: parseHTML(
        "<span style='font-size:1rem; font-weight:500;'><span style='color:#2c2c2c'>210cm</span>"
      ),
      getValueLabel: value =>
        parseHTML(
          `<span><span style='font-size:1.75rem; color:#2c2c2c;'>${value}cm`
        ),
    },
    createFilter: selection => {
      const filterFunctions = {
        small: {
          filterBySeatHeight: seatHeight => (seatHeight < 820 ? true : false),
        },
        average: {
          filterBySeatHeight: seatHeight =>
            seatHeight > 760 && seatHeight < 830 ? true : false,
        },
        large: {
          filterBySeatHeight: seatHeight => (seatHeight > 789 ? true : false),
        },
      }

      if (selection < 160) {
        return filterFunctions.small
      } else if (selection > 190) {
        return filterFunctions.large
      } else {
        return filterFunctions.average
      }
    },
  },
]

export const PAGE = 0
export const SIZE = 6

