import { graphql, useStaticQuery } from "gatsby"
import React from "react"

import RecommendationsQuiz from "./RecommendationsQuiz"
import { QUIZ_QUESTIONS } from "./recommendationQuizUtils"

export const fragment = graphql`
  fragment RecommendationsQuizFragment on WpPage_Flexlayouts_FlexibleLayouts_RecommendationsQuiz {
    pageTitle
    quizHeader {
      title
      description
    }
    resultsHeader {
      title
      description
    }
  }
`

export const ACFRecommendationsQuiz = ({
  pageTitle,
  quizHeader,
  resultsHeader,
  pageContext,
}) => {

  const defaultProducts = []

  return (
    <RecommendationsQuiz
      pageContext={pageContext}
      pageTitle={pageTitle}
      quizHeader={quizHeader}
      resultsHeader={resultsHeader}
      questions={QUIZ_QUESTIONS}
      defaultProducts={defaultProducts}
    />
  )
}
