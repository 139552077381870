import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row } from "react-bootstrap"
import { v4 as uuidv4 } from "uuid"

import leftIcon from "./assets/left-arrow.svg"
import { QuizHeaderShape, QuestionsShape } from "./quizShapes"
import { TextBlock, Link } from "../../UI/Common"

import QuizAnswerSlider from "./QuizAnswerSlider"
import QuizAnswerSelector from "./QuizAnswerSelector"
import * as styles from "./QuizQuestionSection.module.scss"
import ProgressBar from 'react-bootstrap/ProgressBar';

const QuizQuestionSection = ({
  questions,
  quizHeader,
  onFinish,
}) => {
  const initialState = {
    questionIndex: 0,
    selectedAnswer: "",
    allAnswers: [],
    currentFilter: "",
    allFilters: [],
  }
  const [state, setState] = useState(initialState)
  const progressBarPercentage = (100 * state.questionIndex / 6).toFixed()

  const handleClickBack = () => {
    const {
      questionIndex,
      selectedAnswer,
      allAnswers,
      allFilters,
    } = state
    if (selectedAnswer) {
      setState({
        ...state,
        questionIndex: questionIndex - 1,
        selectedAnswer: allAnswers[questionIndex - 1],
        // allAnswers: allAnswers.splice(questionIndex - 1, 1),
        currentFilter: allFilters[questionIndex - 1],
        // allFilters: allFilters.splice(questionIndex - 1, 1),
      })
    } else {
      setState({
        ...state,
        questionIndex: questionIndex - 1,
        selectedAnswer: allAnswers[questionIndex - 1],
        currentFilter: allFilters[questionIndex - 1],
      })
    }
  }

  const handleClickSkip = () => {
    const {
      questionIndex,
      allAnswers,
      allFilters,
    } = state

    const results = {
      answers: [...allAnswers, {}],
      filters: [...allFilters, {}],
    }

    if (questionIndex + 1 === questions.length) {
      onFinish(results)
    }

    if (questions[questionIndex + 1].dependencyQuestionId) {
      setState({
        ...state,
        questionIndex: questionIndex + 2,
        selectedAnswer: "",
        allAnswers: results.answers,
        currentFilter: "",
        allFilters: results.filters,
      })
    } else {
      setState({
        ...state,
        questionIndex: questionIndex + 1,
        selectedAnswer: "",
        allAnswers: results.answers,
        currentFilter: "",
        allFilters: results.filters,
      })
    }
  }

  const handleSelectAnswer = (answer, filter) => {
    const {
      questionIndex,
      allAnswers,
      allFilters,
    } = state
    const results = {
      answers: allAnswers,
      filters: allFilters,
    }

    results.answers[questionIndex] = answer
    results.filters[questionIndex] = filter

    const quizBox = document.getElementById("quiz-box")
    const quizBoxTop = quizBox.offsetTop - 90

    window.scrollTo({
      top: quizBoxTop,
      behavior: "smooth",
    })

    if (questionIndex + 1 === questions.length) {
      onFinish(results)
    } else {
      setState({
        ...state,
        questionIndex: questionIndex + 1,
        selectedAnswer: "",
        allAnswers: results.answers,
        currentFilter: "",
        allFilters: results.filters,
      })
    }
  }

  const handleSliderSelection = (answer, filter) => {
    setState({
      ...state,
      selectedAnswer: answer,
      currentFilter: filter,
    })
  }

  const handleSliderSelectClick = () => {
    const {
      questionIndex,
      selectedAnswer,
      allAnswers,
      currentFilter,
      allFilters,
    } = state

    const results = {
      answers: allAnswers,
      filters: allFilters,
    }

    results.answers[questionIndex] = selectedAnswer
    results.filters[questionIndex] = currentFilter

    const quizBox = document.getElementById("quiz-box")
    const quizBoxTop = quizBox.offsetTop - 90

    window.scrollTo({
      top: quizBoxTop,
      behavior: "smooth",
    })

    if (questionIndex + 1 === questions.length) {
      onFinish(results)
    } else {
      setState({
        ...state,
        questionIndex: questionIndex + 1,
        selectedAnswer: "",
        allAnswers: results.answers,
        currentFilter: "",
        allFilters: results.filters,
      })
    }
  }

  return (
    <>
    <ProgressBar now={progressBarPercentage} variant="primary" label={`${progressBarPercentage}%`}/>
      <Row className={`${styles.container} header-hoist`}>
        <div className={styles.quizBox} id="quiz-box">
          <h2 className="sr-only">{quizHeader.title}</h2>

          <div className={styles.quizBoxHeader}>
            <div className={styles.quizBoxHeaderQuestionBox}>
              <h3 className="h2">{questions[state.questionIndex].question}</h3>
              <TextBlock
                text={questions[state.questionIndex].instructions}
                alignment="center"
              />
              {questions[state.questionIndex].link && (
                <div className={styles.linkWrapper}>
                  <Link
                    link={questions[state.questionIndex].link.url}
                    title={questions[state.questionIndex].link.title}
                    target="_blank"
                  />
                </div>
              )}
            </div>
          </div>

          {questions && questions.length && (
            <>
              {questions[state.questionIndex]?.questionType === "slider" ? (
                <QuizAnswerSlider
                  className="mb-5"
                  key={uuidv4()}
                  question={questions[state.questionIndex]}
                  handleSliderSelection={handleSliderSelection}
                  handleSliderSelectClick={handleSliderSelectClick}
                  currentValue={state.selectedAnswer}
                />
              ) : (
                <div className="d-flex flex-column flex-lg-row justify-content-center">
                  {questions[state.questionIndex].dependencyQuestionId ? (
                    <>
                      {/* mvp trial (mental) implmentation of previous answer dependency. */}
                      {Object.keys(
                        questions[state.questionIndex].dependencyAnswers[
                          state.allAnswers[state.questionIndex - 1]
                        ].answers
                      ).map(key => {
                        const dependencyQuestion =
                          questions[state.questionIndex].dependencyAnswers[
                            state.allAnswers[state.questionIndex - 1]
                          ]

                        const options = dependencyQuestion.answers
                        const filters = dependencyQuestion.filters

                        return (
                          <QuizAnswerSelector
                            className={styles.quizSelectorQuestionBox}
                            key={uuidv4()}
                            answerKey={key}
                            question={dependencyQuestion}
                            answer={options[key]}
                            filter={filters[key]}
                            handleSelectAnswer={handleSelectAnswer}
                            isSelected={state.selectedAnswer === options[key]}
                          />
                        )
                      })}
                    </>
                  ) : (
                    <>
                      {Object.keys(questions[state.questionIndex].answers).map(
                        key => {
                          const options = questions[state.questionIndex].answers
                          const filters = questions[state.questionIndex].filters

                          return (
                            <QuizAnswerSelector
                              className={styles.quizSelectorQuestionBox}
                              key={uuidv4()}
                              answerKey={key}
                              question={questions[state.questionIndex]}
                              answer={options[key]}
                              filter={filters[key]}
                              handleSelectAnswer={handleSelectAnswer}
                              isSelected={state.selectedAnswer === options[key]}
                            />
                          )
                        }
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        </Row>
      <div className={styles.quizActionsBox}>
        {state.questionIndex === 0 ? (
          <div />
        ) : (
          <span
            role="button"
            className={styles.quizNavBtn}
            onClick={handleClickBack}
            tabIndex={0}
            onKeyDown={handleClickBack}>
            <img src={leftIcon} width={16} height={16} alt="back" />
            <span>Back</span>
          </span>
        )}
        <span
          role="button"
          className={styles.quizNavBtn}
          onClick={handleClickSkip}
          tabIndex={0}
          onKeyDown={handleClickSkip}>
          <img src={leftIcon} width={16} height={16} alt="skip" />
          <span>Skip</span>
        </span>
      </div>

    </>
  )
}

QuizQuestionSection.propTypes = {
  questions: QuestionsShape,
  quizHeader: QuizHeaderShape,
  onFinish: PropTypes.func.isRequired,
}

QuizQuestionSection.defaultProps = {
  questions: [],
  quizHeader: {},
}

export default QuizQuestionSection
