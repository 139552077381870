// extracted by mini-css-extract-plugin
export var bgGrey100 = "ProductsCarousel-module--bg-grey-100--0c8dd";
export var bgGrey150 = "ProductsCarousel-module--bg-grey-150--4d3fb";
export var bgGrey200 = "ProductsCarousel-module--bg-grey-200--24524";
export var bgGrey300 = "ProductsCarousel-module--bg-grey-300--1da9c";
export var bgGrey400 = "ProductsCarousel-module--bg-grey-400--047bc";
export var bgGrey500 = "ProductsCarousel-module--bg-grey-500--a15dc";
export var bgGrey600 = "ProductsCarousel-module--bg-grey-600--0f085";
export var bgGrey700 = "ProductsCarousel-module--bg-grey-700--80f6b";
export var bgGrey800 = "ProductsCarousel-module--bg-grey-800--5e655";
export var bgGrey900 = "ProductsCarousel-module--bg-grey-900--05253";
export var innerWrapper = "ProductsCarousel-module--innerWrapper--57978";
export var sectionCls = "ProductsCarousel-module--sectionCls--3e7cd";
export var textGrey100 = "ProductsCarousel-module--text-grey-100--6643e";
export var textGrey150 = "ProductsCarousel-module--text-grey-150--c7629";
export var textGrey200 = "ProductsCarousel-module--text-grey-200--1d846";
export var textGrey300 = "ProductsCarousel-module--text-grey-300--fb019";
export var textGrey400 = "ProductsCarousel-module--text-grey-400--50731";
export var textGrey500 = "ProductsCarousel-module--text-grey-500--2a6e4";
export var textGrey600 = "ProductsCarousel-module--text-grey-600--f3b2d";
export var textGrey700 = "ProductsCarousel-module--text-grey-700--18d9d";
export var textGrey800 = "ProductsCarousel-module--text-grey-800--3d129";
export var textGrey900 = "ProductsCarousel-module--text-grey-900--a6294";
export var wrapper = "ProductsCarousel-module--wrapper--e7733";