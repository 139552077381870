// extracted by mini-css-extract-plugin
export var bgGrey100 = "QuizQuestionSection-module--bg-grey-100--a52f9";
export var bgGrey150 = "QuizQuestionSection-module--bg-grey-150--48c51";
export var bgGrey200 = "QuizQuestionSection-module--bg-grey-200--a2111";
export var bgGrey300 = "QuizQuestionSection-module--bg-grey-300--d7a6d";
export var bgGrey400 = "QuizQuestionSection-module--bg-grey-400--9bfe7";
export var bgGrey500 = "QuizQuestionSection-module--bg-grey-500--8783b";
export var bgGrey600 = "QuizQuestionSection-module--bg-grey-600--805d2";
export var bgGrey700 = "QuizQuestionSection-module--bg-grey-700--395c2";
export var bgGrey800 = "QuizQuestionSection-module--bg-grey-800--900d6";
export var bgGrey900 = "QuizQuestionSection-module--bg-grey-900--97e2d";
export var container = "QuizQuestionSection-module--container--4edda";
export var description = "QuizQuestionSection-module--description--70d5e";
export var linkWrapper = "QuizQuestionSection-module--linkWrapper--22022";
export var nextBtnContent = "QuizQuestionSection-module--nextBtnContent--b03a5";
export var quizActionsBox = "QuizQuestionSection-module--quizActionsBox--93830";
export var quizBox = "QuizQuestionSection-module--quizBox--9a773";
export var quizBoxHeader = "QuizQuestionSection-module--quizBoxHeader--48da0";
export var quizBoxHeaderCounterText = "QuizQuestionSection-module--quizBoxHeaderCounterText--d7cdc";
export var quizBoxHeaderQuestionBox = "QuizQuestionSection-module--quizBoxHeaderQuestionBox--c0d8d";
export var quizBoxHeaderQuestionImg = "QuizQuestionSection-module--quizBoxHeaderQuestionImg--b0e24";
export var quizNavBtn = "QuizQuestionSection-module--quizNavBtn--7d1a1";
export var quizNextButton = "QuizQuestionSection-module--quizNextButton--fa16c";
export var quizSelectorQuestionBox = "QuizQuestionSection-module--quizSelectorQuestionBox--acb43";
export var textGrey100 = "QuizQuestionSection-module--text-grey-100--a7de5";
export var textGrey150 = "QuizQuestionSection-module--text-grey-150--e1a3b";
export var textGrey200 = "QuizQuestionSection-module--text-grey-200--3f8a9";
export var textGrey300 = "QuizQuestionSection-module--text-grey-300--59eb2";
export var textGrey400 = "QuizQuestionSection-module--text-grey-400--439c2";
export var textGrey500 = "QuizQuestionSection-module--text-grey-500--67217";
export var textGrey600 = "QuizQuestionSection-module--text-grey-600--1047e";
export var textGrey700 = "QuizQuestionSection-module--text-grey-700--71ca8";
export var textGrey800 = "QuizQuestionSection-module--text-grey-800--314d5";
export var textGrey900 = "QuizQuestionSection-module--text-grey-900--652b9";