import React from "react"
import { v4 as uuidv4 } from "uuid"
import SaleFlag from "../../../templates/ProductUI/SaleFlag"
import { largeSlideCls } from "../../UI/Slider/Slide.module.scss"
import { SlideBody, ExternalSlideBody, SlideHeader } from "../../UI/Slider/Slide"

const Card = ({ product }) => {
  const { featuredImage, imageSrc, onSale } = product
  return (
    <>
      {onSale && <SaleFlag />}
      <div className={largeSlideCls} key={uuidv4()}>
        {featuredImage && <SlideBody image={featuredImage} />}
        {imageSrc && <ExternalSlideBody product={product} />}
        {product && <SlideHeader product={product} />}
      </div>
    </>
  )
}

export default Card
