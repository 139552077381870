export const DEFAULT_PADDING = {
  padding: {
    top: {
      topMobile: "pt-8",
      topTablet: "pt-md-10",
      topDesktop: "pt-lg-13",
    },
    bottom: {
      bottomMobile: "pb-8",
      bottomTablet: "pb-md-10",
      bottomDesktop: "pb-lg-13",
    },
  },
}

export const DEFAULT_TOP_PADDING = {
  padding: {
    top: {
      topMobile: "pt-8",
      topTablet: "pt-md-10",
      topDesktop: "pt-lg-13",
    },
    bottom: {
      bottomMobile: "pb-0",
      bottomTablet: "pb-md-0",
      bottomDesktop: "pb-lg-0",
    },
  },
}

export const DEFAULT_BOTTOM_PADDING = {
  padding: {
    top: {
      topMobile: "pt-0",
      topTablet: "pt-md-0",
      topDesktop: "pt-lg-0",
    },
    bottom: {
      bottomMobile: "pb-8",
      bottomTablet: "pb-md-10",
      bottomDesktop: "pb-lg-13",
    },
  },
}

export const DEFAULT_SLIDER_PADDING = {
  padding: {
    top: {
      topMobile: "pt-8",
      topTablet: "pt-md-10",
      topDesktop: "pt-lg-13",
    },
    bottom: {
      bottomMobile: "pb-14",
      bottomTablet: "pb-md-15",
      bottomDesktop: "pb-lg-4",
    },
  },
}
export const DEFAULT_END_SLIDER_PADDING = {
  padding: {
    top: {
      topMobile: "pt-8",
      topTablet: "pt-md-10",
      topDesktop: "pt-lg-13",
    },
    bottom: {
      bottomMobile: "pb-14",
      bottomTablet: "pb-md-15",
      bottomDesktop: "pb-lg-13",
    },
  },
}
