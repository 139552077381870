// extracted by mini-css-extract-plugin
export var answerBox = "QuizAnswerSelector-module--answerBox--7ddaf";
export var answerBoxSelected = "QuizAnswerSelector-module--answerBoxSelected--f52bc";
export var answerCode = "QuizAnswerSelector-module--answerCode--7a442";
export var bgGrey100 = "QuizAnswerSelector-module--bg-grey-100--188b8";
export var bgGrey150 = "QuizAnswerSelector-module--bg-grey-150--49e35";
export var bgGrey200 = "QuizAnswerSelector-module--bg-grey-200--66c7b";
export var bgGrey300 = "QuizAnswerSelector-module--bg-grey-300--b05df";
export var bgGrey400 = "QuizAnswerSelector-module--bg-grey-400--fd423";
export var bgGrey500 = "QuizAnswerSelector-module--bg-grey-500--c09d6";
export var bgGrey600 = "QuizAnswerSelector-module--bg-grey-600--73260";
export var bgGrey700 = "QuizAnswerSelector-module--bg-grey-700--08181";
export var bgGrey800 = "QuizAnswerSelector-module--bg-grey-800--7d4f4";
export var bgGrey900 = "QuizAnswerSelector-module--bg-grey-900--a175f";
export var box = "QuizAnswerSelector-module--box--37d77";
export var descriptionCls = "QuizAnswerSelector-module--descriptionCls--23ce2";
export var imgCls = "QuizAnswerSelector-module--imgCls--9551a";
export var labelCls = "QuizAnswerSelector-module--labelCls--2e594";
export var large = "QuizAnswerSelector-module--large--b0106";
export var textArea = "QuizAnswerSelector-module--textArea--fdeac";
export var textGrey100 = "QuizAnswerSelector-module--text-grey-100--5d932";
export var textGrey150 = "QuizAnswerSelector-module--text-grey-150--2b14f";
export var textGrey200 = "QuizAnswerSelector-module--text-grey-200--9b22a";
export var textGrey300 = "QuizAnswerSelector-module--text-grey-300--7781c";
export var textGrey400 = "QuizAnswerSelector-module--text-grey-400--7f944";
export var textGrey500 = "QuizAnswerSelector-module--text-grey-500--9209c";
export var textGrey600 = "QuizAnswerSelector-module--text-grey-600--facc1";
export var textGrey700 = "QuizAnswerSelector-module--text-grey-700--88bf7";
export var textGrey800 = "QuizAnswerSelector-module--text-grey-800--c8fa0";
export var textGrey900 = "QuizAnswerSelector-module--text-grey-900--bd67a";