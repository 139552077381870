import React from "react"
import { Container} from "react-bootstrap"
import Slider, { createSliderWithTooltip } from "rc-slider"
import "rc-slider/assets/index.css"
import { debounce } from "lodash/function"
import Button from "./Button"

import * as styles from "./QuizAnswerSilder.module.scss"

const QuizAnswerSilder = ({
  question,
  isSelected,
  handleSliderSelection,
  handleSliderSelectClick,
  className,
  currentValue,
}) => {
  const { labels, answers, createFilter } = question

  const handleChange = selection => {
    const filter = createFilter(selection)

    handleSliderSelection(selection, filter)
  }

  const value = currentValue ? currentValue : (answers.min + answers.max) / 2

  const SliderWithTooltip = createSliderWithTooltip(Slider)

  const marks = { [answers.min]: labels.min, [answers.max]: labels.max }

  const debounceOnChange = debounce(handleChange, 1000)

  return (
    <Container className="d-flex flex-column align-items-center justify-content-center my-14">
     
      <div className={styles.sliderWrapper}>
      <SliderWithTooltip
          step={1}
          min={answers.min}
          max={answers.max}
          defaultValue={value}
          allowCross={false}
          onChange={selection => debounceOnChange(selection)}
          marks={marks}
          dotStyle={{ borderColor: "#2c2c2c" }}
          railStyle={{ backgroundColor: "#a7a7a7" }}
          handleStyle={[
            { borderColor: "#2c2c2c", boxShadow: "none" },
            { borderColor: "#2c2c2c", boxShadow: "none" },
          ]}
          trackStyle={[
            { backgroundColor: "#2c2c2c" },
            { backgroundColor: "#2c2c2c" },
          ]}
          tipFormatter={value =>
            
            labels.getValueLabel(value)
          }
          tipProps={{ visible: true }}
          
        />
      </div>

      <Button
        className={styles.quizNextButton}
        // isDisabled={state.selectedAnswer === ""}
        onClick={handleSliderSelectClick}>
        <span className={styles.nextBtnContent}>
          Select
        </span>
      </Button>
    </Container>
  )
}

export default QuizAnswerSilder
